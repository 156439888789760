<template>

	<div v-touch:swipe.left.stop.prevent="nextFilter" v-touch:swipe.right.stop.prevent="prevFilter">

		<h1><i class="fas fa-traffic-cone"></i> Travaux supplémentaires à prévoir</h1>



		<div
				class="block block-rounded block-bordered js-appear-enabled animated fadeIn"
				data-toggle="appear"
				v-for="(travail, index) in intervention.travauxsupp"
		>
			<div class="block-header block-header-default">
				<a class="font-w600" href="javascript:void(0)">Travaux Supplémentaire #{{index+1}}</a>
				<div class="block-options">
					<div class="dropdown">
						<button type="button" class="btn-block-option dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
						<div class="dropdown-menu dropdown-menu-right">
							<a class="dropdown-item" @click.prevent="removeJob(index)" href="#">
								<i class="far fa-fw fa-times-circle text-danger mr-1"></i> Supprimer ces travaux
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="block-content">

				<b-form-group class="my-2" :class="{required:true}"
							  :key="'select-typetravaux-'+index"
							  label="Type de travaux supplémentaires à prévoir" :label-for="'select-typetravaux-'+index">
					<b-form-select
							:name="'select-typetravaux-'+index"
							:id="'select-typetravaux-'+index"
							v-model="intervention.travauxsupp[index].type"
							:class="{'is-invalid':false}
					">
						<option v-for="opt in typetrauvaux" :value="opt">{{opt}}</option>
					</b-form-select>

				</b-form-group>

				<pictures :uid="eventid" :key="'travailsupp-photo-'+travail.uid" :name="'travauxsupp-photos[' +  travail.uid + ']'" label="Photo " class="my-2">PICTURESS</pictures>

				<div class="form-group my-2" :class="{required:false}">
					<label :for="'travauxsupp-comments['+index+']'">Raisons de la préconisation :</label>
					<textarea class="form-control form-control-alt" :required="false" v-model="intervention.travauxsupp[index]['comment']"
							  :name="'travauxsupp-comments[index]'" :id="'travauxsupp-comments['+index+']'"></textarea>

				</div>

			</div>

		</div>


	<button @click="addJob()" class="btn my-4 btn-block btn-info"><i class="far fa-plus-circle"></i> Ajouter une préconisation </button>

		<button
				class="btn btn-block btn-primary"
				@click="nextFormPage('resultmission-1-bontravail')"
		>
			<i class="fas fa-user-hard-hat"></i> Bon de travail
		</button>
	</div>
</template>


<script>
  // @ is an alias to /src
  import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';
  import {mapGetters, mapActions} from 'vuex'

  export default {
    mixins: [ResultMissionMixin],
    beforeMount: async function () {
      if (!this.intervention.travauxsupp){
        this.$set(this.intervention, 'travauxsupp', []);
       //  this.saveResultToStore();
      }
    },
	data: function(){
      return {
        typetrauvaux: [
          'ITV',
          'Maçonnerie',
          'Eléments cassés à remplacer',
          'Curage complémentaire',
          'Proposition de contractualisation',
          'Autre'
		]
	  }
	},
    methods: {
      ...mapActions('pictures',{deletePicturesByName: 'deleteByName'}),
	  prevFilter: function () {
          this.nextFormPage('resultmission-1-precisions');
        },
      nextFilter: function () {
        this.nextFormPage('resultmission-1-final');
      },
      addJob: function(){
        this.intervention.travauxsupp.push({
          'comment' : '', 'type': null, 'uid': new Date().getTime()
        });
        this.saveResultToStore();

      },

      removeJob: async function (index) {
        await this.deletePicturesByName('travailsupp-photos[' + index + ']');
        this.intervention.travauxsupp.splice(index, 1);
        this.saveResultToStore();
      }
    }
  }
</script>

