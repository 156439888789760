<template>
	<div>
		<label class="d-block">{{label}}</label>
        <label class="btn btn-success btn-lg font-weight-normal mr-2" style="cursor: pointer">
            Prendre une photo
            <input type="file" hidden multiple accept="image/x-png,image/jpeg,image/gif" @change="upload" capture/>
        </label>
        <label class="btn btn-info btn-lg font-weight-normal ml-2" style="cursor: pointer">
            Chercher une photo
            <input type="file" hidden multiple accept="image/x-png,image/jpeg,image/gif" @change="upload"/>
        </label>
		<div class="row">
			<div class="col-6" v-for="(file, key) in getByName(name)" :key="key">
				<img :src="file.base64" class="img-thumbnail">
				<div class="btn btn-danger" @click="remove(file)"><i class="fa fa-times"></i></div>
				<textarea class="form-control" :value="file.comment" @change="updateComment($event, file)" ></textarea>
			</div>
		</div>
	</div>
</template>
<style>
	img.img-thumbnail {
		max-height: 250px;
	}
</style>
<script>
	import {mapActions, mapGetters} from 'vuex'
	
	export default {
		name: "Pictures",
		props: ['name', 'uid', 'label'],
		// data: function () {
		// 	return {
		// 		files: []
		// 	}
		// },
		beforeMount: async function () {
			await this.$store.dispatch('pictures/load', this.uid);
			console.log('beforrrr');
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('pictures', [
				'getByName'
			]),
			// mix the getters into computed with object spread operator
			...mapGetters('config', {'config': 'getConfig'}),
		},
		methods: {
			// mise à jour du commentaire
			updateComment: function(event, picture){
				this.$store.dispatch('pictures/updateComment', {picture, comment: event.target.value});

			},
			remove: function (myfile) {
				if (confirm('Êtes vous sûr de vouloir supprimer cette photo ?')) {
				    this.$store.dispatch('pictures/delete', myfile);
                }
			},
			upload: async function (elt) {
				let self = this;
				let files = elt.target.files;
			  // comme un forEach d'un tableau mais attend le await pour passer à l'element suivant
              async function asyncForEach(array, callback) {
                for (let index = 0; index < array.length; index++) {
                  await callback(array[index], index, array);
                }
              }

              asyncForEach(files, async function (file) {
					let myBase64File = await self.convert(file);
					//attente de la sauvegarde pour ne pas avoir de problème d'index pour le meme champ en double
                  	await self.$store.dispatch('pictures/save', {base64: myBase64File, name: self.name})
				});
			},
			convert: async function (myFile) {
				let self = this;
				return new Promise((resolve, reject) => {
					let fileReader = new FileReader();
					if (fileReader && myFile) {
						fileReader.onload = () => {
							// resize de l'image
							const img = new Image();
							img.src = fileReader.result;
							
							img.onload = () => {
								const elem = document.createElement('canvas');
								const ctx = elem.getContext('2d');
								// img.width and img.height will contain the original dimensions
								ctx.drawImage(img, 0, 0);
								let MAX_WIDTH = self.config.camera.targetWidth; //@TODO récupérer la valeur dans la config
								let MAX_HEIGHT = self.config.camera.targetWidth;
								let width = img.width;
								let height = img.height;
								
								if (width > height) {
									if (width > MAX_WIDTH) {
										height *= MAX_WIDTH / width;
										width = MAX_WIDTH;
									}
								} else {
									if (height > MAX_HEIGHT) {
										width *= MAX_HEIGHT / height;
										height = MAX_HEIGHT;
									}
								}
								ctx.canvas.width = width;
								ctx.canvas.height = height;
								let ctx2 = ctx.canvas.getContext("2d");
								ctx2.drawImage(img, 0, 0, width, height);
								
								resolve(ctx2.canvas.toDataURL('image/jpeg'));
							}
						};
						
						fileReader.onerror = (error) => {
							reject(error);
						};
						fileReader.readAsDataURL(myFile);
					} else {
						reject('No file provided');
					}
					
				})
			}
		},
	}
</script>

<style scoped>

</style>