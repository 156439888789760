<template>
	<div class="d-flex justify-content-start">
		<a v-if="value" :href="racine+value" class="btn btn-outline-dark mr-2 d-flex align-items-center">
			<i class="far fa-map-marked-alt"></i>
		</a>
		<div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LinkAddress",
		props: ['value'],
		data: function () {
			return {racine: ''}
		},
		mounted: function () {
			if ((navigator.platform.indexOf("iPhone") != -1) ||
				(navigator.platform.indexOf("iPad") != -1) ||
				(navigator.platform.indexOf("iPod") != -1)) {
				/* if we're on iOS, open in Apple Maps */
				this.racine = 'maps://maps.google.com/maps?q=';
			} else { /* else use Google */
				this.racine = "https://maps.google.com/maps?q=";
			}
		},
		
	}
</script>

<style scoped>

</style>