import VueSignaturePad from 'vue-signature-pad';
import Pictures from '@/components/Pictures.vue';
import {mapActions, mapGetters} from 'vuex'


const mixin = {

  props: {
    'eventid': Number|String
  },

  data: function() {

    let self = this;
    return {
      errors: {},
      intervention: null
    }
  },

  components: {VueSignaturePad, Pictures},
  created: async function () {
    console.log('created mixin resultmission', this);

    this.intervention = this.getIntervention(this.eventid);

  },
  // updated: async function () {
  //   let self = this;
  //   console.log('updated', this.action);
  //   if (this.action) {
  //     this.form.map(item => {
  //
  //       if (item.Type == 'multiselect'){
  //         if(self.intervention[item.Name] == undefined){
  //           self.intervention[item.Name] = [];
  //         }
  //       }
  //     });
  //   }
  // },

  computed: {
    ...mapGetters('interventions', [
      'getIntervention'
    ]),
  },
  methods: {
    ...mapActions('interventions', ['setIntervention', 'finishIntervention']),

    saveResultToStore: function(){
      this.setIntervention({uid: this.eventid, data: this.intervention});
    },
    nextFormPage: function (pagename) {
      this.saveResultToStore();
      this.$router.push({
        'name': pagename,
        'params': {eventid: this.eventid}
      });
    },

    checkEmail: function(email) {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // nextPage: function () {
    //   let self = this;
    //   self.errors = {};
    //   // enregistrement des signatures s'il y en a
    //   this.form.map(item => {
    //     if (item.Page == self.page) {
    //       if (item.Type == 'signature') {
    //         self.saveSignature(item.Name);
    //       } else if (item.Type == 'email' && self.intervention[item.Name]) {
    //         // check de l'email s'il est complété
    //         if (!self.checkEmail(self.intervention[item.Name])) {
    //           self.errors[item.Name] = self.$t('Invalid email');
    //         }
    //       }
    //       if (item.Required && !self.intervention[item.Name]){
    //         self.errors[item.Name] = self.$t('Required input');
    //       }
    //     }
    //   });
    //
    //   if (Object.keys(self.errors).length > 0) {
    //     return ;
    //   }
    //
    //   if (this.page == this.totalPages) {
    //     this.intervention.syncfinished = true;
    //     this.setIntervention({uid: this.eventid, data: this.intervention});
    //     this.finishIntervention({uid: this.eventid});
    //     this.$router.push({name: 'home'});
    //   } else {
    //     this.setIntervention({uid: this.eventid, data: this.intervention});
    //     console.log('nextpage');
    //     this.$router.push({
    //       'name': 'resultMission',
    //       'params': {eventid: this.eventid, 'action': self.action, 'page': Number(this.page) + 1}
    //     });
    //   }
    // },
    loadSignature: function(name) {
      let self = this;
      self.$refs[name].fromDataURL(self.intervention[name]);
    },

    clearSignature: function (name) {
      this.$refs[name].clearSignature();
      this.intervention[name] = null;
      this.saveResultToStore();

    },
    saveSignature: function (name) {
      console.log('save signe', name, this);
      let sign = this.$refs[name].saveSignature();
      this.intervention[name] = sign.data;
      this.saveResultToStore();
    },
    getGeoloc: function(){
      let self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {

            if (!self.intervention.geoloc || !self.intervention.geoloc.latitude) {
              self.intervention.geoloc.latitude = position.coords.latitude;
              self.intervention.geoloc.longitude = position.coords.longitude;
              self.intervention.geoloc.accuracy = position.coords.accuracy;
              self.saveResultToStore();
            }

        });
      }
    }
  }

};

export default mixin;