<template>
	<div>
		<div class="block block-fx-shadow" v-if="event">
			<div class="block-content block-content-full info py-2"
				 :style="{'border-left-color': event.color}">
				<div class="font-size-h3">{{event.client.name}}</div>
				<div v-if="event.title">{{event.title | nl2br}}</div>
				<div v-if="event.contactsite">{{event.contactsite | nl2br}}</div>
				<div v-if="event.comment" v-html="$options.filters.nl2br(event.comment)"></div>
				
				<div class="row" v-if="event.client.address">
					<div class="col py-1">
						<link-address
								v-model='event.client.address+" "+event.client.city'>
							{{event.client.address}}<br>
							{{event.client.zipcode}} {{event.client.city}}
						</link-address>
					</div>
				</div>
				<div class="row" v-if="event.client.phone">
					<div class="col py-1">
						<tel v-model="event.client.phone"></tel>
					</div>
				</div>
				<div class="row" v-if="event.client.email">
					<div class="col py-1">
						<email v-model="event.client.email"></email>
					</div>
				</div>
				<div class="row">
					<p v-if="event.technician_comment" v-html="$options.filters.nl2br(event.technician_comment)"></p>
				</div>
				<div class="row" v-if="event.contactnow.name">
					<div class="col">
						<p class="text-danger font-weight-bold mt-3 mb-0">Contact à appeler</p>
						<div class="font-weight-bold">{{event.contactnow.name}}</div>
						<div>{{event.contactnow.fonction}}</div>
						<div>{{event.contactnow.callobject}}</div>
						<tel v-model="event.contactnow.mobile"></tel>
            			<tel v-model="event.contactnow.tel"></tel>
					</div>
				</div>
			</div>
		</div>
		<div class="row text-center my-4" v-if="event">
			<div class="col-4">
				<i class="fal fa-2x fa-stopwatch"></i><br>{{event.duration}}
			</div>
			<div class="col-4">
				<i class="fal fa-2x fa-calendar-alt"></i><br>{{event.start | formatDate}}
			</div>
			<div class="col-4">
				<i class="fal fa-2x fa-clock"></i><br>{{event.start | formatTime}}
			</div>
		</div>
		
		<div class="mt-4" v-if="event">
			<router-link :to="{name: 'resultmission-1-informations', params:{action:action, eventid: event.uid}}"
						 v-for="(action,k) in event.actions" :key="k" class="btn btn-block btn-info text-capitalize">
				{{action.name}}
			</router-link>
		</div>
	</div>
</template>


<script>
	
	// @ is an alias to /src
	import {mapActions, mapGetters} from 'vuex'
	import Tel from '@/components/Tel.vue';
	import Email from '@/components/Email.vue';
	import LinkAddress from '@/components/LinkAddress.vue';
	
	export default {
		name: 'event',
		props: ['eventid'],
		components: {
			Tel, Email, LinkAddress
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('events', [
				'getEvent'
			])
		},
		beforeMount: function () {
			this.event = this.getEvent(this.eventid);
		},
		data: function () {
			return {
				event: {}
			}
		}
	}
</script>

<style>
	.block-content.info {
		border: none;
		border-left: 1px solid black;
		border-left-width: 10px;
		border-radius: 0;
		margin-bottom: 5px;
	}
</style>
