<template>

	<div v-touch:swipe.left.stop.prevent="nextFilter" v-touch:swipe.right.stop.prevent="prevFilter">

	<H1><i class="far fa-play"></i> Etat initial</H1>


		<div
				class="block block-rounded block-bordered js-appear-enabled animated fadeIn"
				data-toggle="appear"
		>
			<div class="block-header block-header-default">
				<a class="font-w600" href="#">Description de l'état initial du chantier</a>
			</div>
			<div class="block-content">
				<div class="form-group my-2" :class="{required:false}">
					<label for="etatinitial-comment">Commentaire sur l'état des lieux initial :</label>
					<textarea class="form-control form-control-alt" :required="false" v-model="intervention['etatinitial-comment']"
							  :class="{'is-invalid':errors['etatinitial-comment']}" name="etatinitial-comment" id="etatinitial-comment"></textarea>
					<div v-if="errors['etatinitial-comment']" class="invalid-feedback">{{errors['etatinitial-comment']}}</div>
				</div>

				<pictures :uid="eventid" name="etatinitial-photo" label="Photo avant le début de l'intervention" class="my-2">PICTURESS</pictures>
			</div>

		</div>

		<button
				class="btn btn-block btn-primary"
				@click="nextFormPage('resultmission-1-bontravail')"
		>
			<i class="fas fa-user-hard-hat"></i> Bon de travail
		</button>


	</div>
</template>

<script>

  // @ is an alias to /src
  import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';

  export default {
    mixins: [ResultMissionMixin],
	methods: {
      prevFilter: function(){
        this.nextFormPage('resultmission-1-informations');
      },
      nextFilter: function(){
        this.nextFormPage('resultmission-1-bontravail');
      },
	},
	created: function(){

      this.getGeoloc();
	}
  }
</script>
