<template>
	<div class="row no-gutters justify-content-center bg-body-dark">
		<div class="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
			<div class="block block-rounded block-transparent block-fx-pop w-100 mb-0 overflow-hidden">
				<div class="row no-gutters">
					<div class="col bg-white">
						<div class="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
							
							<installPrompt></installPrompt>
							<button v-if="updateExists"
									class="btn btn-hero-success btn-block mb-4"
									@click="refreshApp">
								Une nouvelle version est disponible ! Cliquez ici
							</button>
							<div class="mb-2 text-center">
								<a class="link-fx font-w700 font-size-h1" href="index.html">
									<span class="text-primary">Scarponais Intervention</span>
								</a>

								<p class="text-uppercase font-w700 font-size-sm text-muted">{{$t('Log me in')}}</p>
							</div>

							<div class="form-group" v-if="msgerror">
								Erreur : {{msgerror}}
							</div>
							<div class="form-group">
								<input type="text" v-model="username" class="form-control form-control-alt"
									   :placeholder="$t('Username')">
							</div>
							<div class="form-group">
								<input type="password" v-model="password" class="form-control form-control-alt"
									   :placeholder="$t('Password')">
							</div>
							<div class="form-group">
								<button type="submit" class="btn btn-block btn-hero-primary" @click.prevent="login">
									<i v-if="busy" class="fa fa-spinner fa-spin"></i>
									<i v-else class="fa fa-fw fa-sign-in-alt mr-1"></i> {{$t('Log me in')}}
								</button>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapActions, mapGetters} from 'vuex'
	import installPrompt from '@/components/installPrompt.vue';

	export default {
		name: 'Login',
		components: {
			installPrompt
		},
		data: function () {
			return {
				username: '',
				password: '',
				busy: false,
				msgerror: null,
				refreshing: false,
				updateExists: false
			}
		},
		created() {
			// Listen for swUpdated event and display refresh snackbar as required.
			document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
			// Refresh all open app tabs when a new service worker is installed.
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		},
		
		computed: {
			...mapGetters('login', [
				'getToken'
			])
		},
		methods: {
			login: async function () {
				
				this.msgerror = null;
				this.busy = true;
				
				let islogin = await this.attemptLogin({username: this.username, password: this.password});
				this.busy = false
				
				if (islogin === true) {
					await this.refreshconfig();
					this.$router.replace({'name': 'home'});
					
				} else if (islogin !== false) {
					this.msgerror = islogin;
				}
			},
			...mapActions('login', [
				'attemptLogin'
			]),
      		...mapActions('config',{'refreshconfig': 'refresh' }),
		}
	}
</script>
