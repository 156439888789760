<template>
<div v-touch:swipe.left.stop.prevent="nextFilter" v-touch:swipe.right.stop.prevent="prevFilter" class="block-content block-content-full info py-2"
     :style="{'border-left-color': event.color}">
    <div class="font-size-h3">{{event.client.name}}</div>
    <div v-if="event.title">{{event.title | nl2br}}</div>
    <div v-if="event.contactsite">{{event.contactsite | nl2br}}</div>
    <div v-if="event.comment" v-html="$options.filters.nl2br(event.comment)"></div>

    <div class="row" v-if="event.client.address">
        <div class="col py-1">
            <link-address
                    v-model='event.client.address+" "+event.client.city'>
                {{event.client.address}}<br>
                {{event.client.zipcode}} {{event.client.city}}
            </link-address>
        </div>
    </div>
    <div class="row" v-if="event.client.phone">
        <div class="col py-1">
            <tel v-model="event.client.phone"></tel>
        </div>
    </div>
    <div class="row" v-if="event.client.email">
        <div class="col py-1">
            <email v-model="event.client.email"></email>
        </div>
    </div>
    <div class="row">
        <p v-if="event.technician_comment" v-html="$options.filters.nl2br(event.technician_comment)"></p>
    </div>
    <div class="row" v-if="event.contactnow.name">
        <div class="col">
            <p class="text-danger font-weight-bold mt-3 mb-0">Contact à appeler</p>
            <div class="font-weight-bold">{{event.contactnow.name}}</div>
            <div>{{event.contactnow.fonction}}</div>
            <div>{{event.contactnow.callobject}}</div>
            <tel v-model="event.contactnow.mobile"></tel>
            <tel v-model="event.contactnow.tel"></tel>
        </div>
    </div>
    
    <button
            class="btn btn-block btn-primary"
            @click="nextFormPage('resultmission-1-etatinitial')"
    >
        <i class="far fa-play"></i> Etat des lieux initial
    </button>
</div>
</template>
<script>

  // @ is an alias to /src
  import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';
  import version from '@/services/version';
  import {mapActions, mapGetters} from 'vuex'
  import Tel from '@/components/Tel.vue';
  import Email from '@/components/Email.vue';
  import LinkAddress from '@/components/LinkAddress.vue';



  export default {
    mixins: [ResultMissionMixin],
    components: {
      Tel, Email, LinkAddress
    },
    data: function() {
      return {
        event: {}
      }
    },
    methods: {
      prevFilter: function(){
        console.log('laaaaa next')
      },
      nextFilter: function(){
        this.nextFormPage('resultmission-1-etatinitial');
      }
    },
    computed: {
      ...mapGetters('events',{getEvent: 'getEvent'}),
    },
    created: function () {

      // initialisation de la réponse du formulaire
      //si l'intervention est en création
      if (!this.intervention.version) {
        this.intervention = Object.assign({}, this.intervention,
          {
            version: version.version,
            travaux: [],
            travauxsupp: []
          }
        );
        this.saveResultToStore();

      } else if (this.intervention.version !== version.version) {
        // l'intervetion n'est pas de la meme version
        //
        // if (this.intervention.version < '0.1') {
        //   // faire les manipulations pour migrer de 0.1 vers 0.2
        //   this.intervention.version = '0.2';
        // }
        //
        // if (this.intervention.version < '0.2.1') {
        //   // faire les manipulations pour migrer puis passer à la version actuelle
        //   // faire des trucs
        // }

        this.intervention.version = version.version;

        this.saveResultToStore();
      }

      this.event = this.getEvent(this.eventid)
    }

  }
</script>

    <style>
        .block-content.info {
            border: none;
            border-left: 1px solid black;
            border-left-width: 10px;
            border-radius: 0;
            margin-bottom: 5px;
        }
    </style>
