import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(BootstrapVue)
Vue.use(Vue2TouchEvents, {
	swipeTolerance: 50,
});
import * as firebase from "firebase/app";
import "firebase/messaging";

import App from './App.vue'
import router from './router'
import store from './store'
import * as moment from 'moment';
import './registerServiceWorker'

import './assets/js/dashmix/app';
import './styles/main.scss';
import './styles/dashmix/themes/xinspire.scss';
import i18n from './i18n'

var FCMconfig = {
  apiKey: "AIzaSyAGeHbzBbDcvlfJyEi-EfAaIEXGpxy3qiA",
  authDomain: "scarponais.firebaseapp.com",
  databaseURL: "https://scarponais.firebaseio.com",
  projectId: "scarponais",
  storageBucket: "scarponais.appspot.com",
  messagingSenderId: "327175403271",
  appId: "1:327175403271:web:e77eaa86963081717d77a2"
};

firebase.initializeApp(FCMconfig);

Vue.prototype.$messaging = null;

Vue.config.productionTip = false
Vue.filter('formatTime', function (value) {
	if (value) {
		return moment(String(value)).format('HH:mm')
	}
});
Vue.filter('formatDateTime', function (value) {
	if (value) {
		return moment(String(value)).format('DD/MM/YYYY HH:mm')
	}
});

Vue.filter('formatDate', function (value) {
	if (value) {
		return moment(String(value)).format('DD/MM/YYYY')
	}
});
Vue.filter('trim', function (value) {
	return value.trim();
});

Vue.filter('nl2br', function (value) {
	return value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');
});

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')


