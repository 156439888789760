import store from '@/store';
import parser from 'cron-parser';


export class Task {
	constructor (timePolicy, name, runFunction, description){
		this.name = name;
		this.description = description;
		this.timePolicy = timePolicy;
		this.runFunction = runFunction;
		
		this.lastRunTime = window.localStorage.getItem('Task-'+name) || new Date('2020-01-01');
		this.futureRunTime = null;
		this.setFuturRunTime();
	}
	
	setFuturRunTime() {
        let interval = parser.parseExpression(this.timePolicy,{currentDate:this.lastRunTime});
        console.log(this.timePolicy, interval.next().getTime(), this.lastRunTime);
        this.futureRunTime = interval.next().getTime();

	}

    run(){
        if(typeof(this.runFunction) != 'function'){
            console.error('Fonction de la tache manquante');
            return false;
        }

        //test si il faut lancer
        let now = new Date().getTime();
        if(this.futureRunTime < now){
        	console.log('run', this.name);        	
			window.localStorage.setItem('Task-'+this.name, new Date());
            this.runFunction();
            //on actualise date dernier lancement
            this.lastRunTime = now;
            //on prépare la prochaine
            this.setFuturRunTime();
        }

    }

}



export class TaskWorker {
	
	constructor(intervalTime) {
		if (!intervalTime){
			intervalTime = 1000*60;
		}
		this.intervalTime = intervalTime; // en milliseconde
		this.isRun = false;
		this.tasks = [];
		console.log('TaskWorker');
	}
	
	init() {
		this.initTask();
		setInterval(this.makeTasksList.bind(this), this.intervalTime);
	}
	
	initTask(){
		this.tasks.push(new Task("*/10 * * * *", "checkConfig", this.checkConfig));
		this.tasks.push(new Task("*/5 * * * *", "sync", this.sync));
		this.tasks.push(new Task("0 1 * * *", "removeSyncPictures", this.removeSyncPictures));
	}
	
	async checkConfig(){
		await store.dispatch('config/refresh');
	}
	
	async removeSyncPictures(){
		let evtids = store.getters['events/getAllUid'];
		console.log('removeSyncPictures', evtids);
		await store.dispatch('pictures/removeSynced', evtids);
		await store.dispatch('interventions/removeSynced', evtids);
	}
	
	async sync() {
		await store.dispatch('pictures/sync');
		await store.dispatch('interventions/pushAll');
	}
	
    setRunning(){
        this.isRun = true;
    }

    setNotRunning(){
        this.isRun = false;
    }

    isRunning(){
        return this.isRun;
    }

    makeTasksList(){
        //on test si ca ne tourne pas déja(lancement manuel)
        if(this.isRunning()){
            return false;
        }

        //lancement en cours
        this.setRunning();
        this.runTasks();
    }

    runTasks(){
		console.log('runTasks');
        this.tasks.forEach(function(task){
            task.run();
        });

        //tache terminé
        this.setNotRunning();
    }

	
}
