<template>

	<div v-touch:swipe.right.stop.prevent="prevFilter">

	<h1><i class="fas fa-flag-checkered"></i> Etat final de l'intervention</h1>

		<b-form-group class="my-2" :class="{required:true}"
					  label="Etat final de nos travaux" :label-for="'final-etatfinal'">
			<b-form-select
					:name="'final-etatfinal'"
					:id="'final-etatfinal'"
					v-model="intervention['final-etatfinal']"
					:class="{'is-invalid':false}
					">
				<option v-for="(opt, index) in etatfinals" :value="index">{{opt}}</option>
			</b-form-select>

		</b-form-group>

		<div class="form-group my-2" :class="{required:false}">
			<label for="final-comment">Commentaire :</label>
			<textarea class="form-control" :required="false" v-model="intervention['final-comment']"
					  :class="{'is-invalid':errors['final-comment']}" name="final-comment" id="final-comment"></textarea>
			<div v-if="errors['final-comment']" class="invalid-feedback">{{errors['final-comment']}}</div>
		</div>


		<b-form-group class="my-2" :class="{required:true}"
					  label="Niveau de satisfaction" :label-for="'final-satisfaction'">
			<b-form-select
					:name="'final-satisfaction'"
					:id="'final-satisfaction'"
					v-model="intervention['final-satisfaction']"
					:class="{'is-invalid':false}
					">
				<option v-for="(opt, index) in satisfactions" :value="index">{{opt}}</option>
			</b-form-select>

		</b-form-group>


		<div class="row mb-3 mt-2">
			<div class="col">
				<label class="d-block" >Signature client</label>
				<div class="row">
					<div class="col" v-touch:swipe.stop.prevent="">
						<VueSignaturePad name="final-signature" :options="{ // Check out https://github.com/szimek/signature_pad
        'minWidth': 2,
        'onEnd': signCient
      }"
					 class="border border-2x" :ref="'final-signature'"/>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="btn btn-danger float-right" @click="clearSignature('final-signature')">
							{{$t('Erase')}}
						</div>
					</div>
				</div>
			</div>
		</div>

        <b-form-group class="my-2" :class="{required:true}"
                      label="Paiement" :label-for="'final-paiement'">
            <b-form-select
                    :name="'final-paiement'"
                    :id="'final-paiement'"
                    v-model="intervention['final-paiement']"
                    :class="{'is-invalid':false}
					">
                <option v-for="(opt, index) in paiements" :value="index">{{opt}}</option>
            </b-form-select>

        </b-form-group>

		<b-form-group v-if="intervention['final-paiement'] == 'cash' || intervention['final-paiement'] == 'check'" class="my-2"
					  :class="{required:true}"
					  label="Montant" :label-for="`final-montant`">
			<b-form-input :id="`final-montant`" type="text" :class="{'is-invalid':errors[`final-montant`]}"
						  :required="true" v-model="intervention[`final-montant`]"
			></b-form-input>
			<div v-if="errors[`final-montant`]" class="invalid-feedback">{{errors[`final-montant`]}}</div>
		</b-form-group>

		<pictures :uid="eventid" v-if="intervention['final-paiement'] == 'check'" name="final-photo-check" label="Photodu chèque" class="my-2">PICTURESS</pictures>

		<button
				class="btn btn-block btn-primary"
				@click="finish()"
		>
			<i class="far fa-clipboard-check"></i> Fin de l'intervention
		</button>

	</div>
</template>
<script>

  // @ is an alias to /src
  import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';
  import {mapGetters} from 'vuex'


  export default {
    mixins: [ResultMissionMixin],
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters('pictures', {'pictures': 'getAll'})
    },
	methods: {

      prevFilter: function () {
        this.nextFormPage('resultmission-1-travauxsupp');
      },

      signCient: function () {
        this.saveSignature('final-signature');
      },
	  finish: function () {
        this.intervention._pictures = this.pictures().map( p => p.index);
        this.saveResultToStore();
        this.finishIntervention({uid: this.eventid});
        this.$router.push({
          'name': 'home',
        });
      }
	},
    created: function(){
      this.getGeoloc();
    },
	mounted: function () {

      this.loadSignature('final-signature');
    },
    data: function(){
      return {
        paiements: {
          'no': 'Paiement à réception de facture',
          'cash': 'Espèces',
          'check': 'Chèque'
        },
        satisfactions: {
          '1' : '1',
          '2' : '2',
          '3' : '3',
          '4' : '4'
		},
        etatfinals: {
          'done': 'Mission accomplie, tests effectués',
          'standby': 'Travaux non terminés, intervention à poursuivre Alternative restant à déterminer',
          'cantfinish': 'Incapacité d\'action'
        }
      }
    },
  }
</script>
