<template>

    <div v-touch:swipe.left.stop.prevent="nextFilter" v-touch:swipe.right.stop.prevent="prevFilter">
        <h1><i class="fas fa-user-hard-hat"></i> Bon de travail</h1>

        <div
                class="block block-rounded block-bordered js-appear-enabled animated fadeIn"
                data-toggle="appear"
                v-for="(travail, index) in intervention.travaux"
        >
            <div class="block-header block-header-default">
                    <a class="font-w600" href="javascript:void(0)">Travaux #{{index+1}}</a>
                <div class="block-options">
                    <div class="dropdown">
                        <button type="button" class="btn-block-option dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" @click.prevent="removeJob(index)" href="#">
                                <i class="far fa-fw fa-times-circle text-danger mr-1"></i> Supprimer ces travaux
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-content">
                Description des travaux effectués
                <pictures :uid="eventid" :key="'travail-photo-'+travail.uid" :name="'travaux-photos[' +  travail.uid + ']'"
                          label="Photo(s)" class="my-2">PICTURESS
                </pictures>

            </div>
            <div class="block-content block-content-full bg-body-light">

                <div class="form-group my-2" :class="{required:false}">
                    <label :for="'travaux-comments['+index+']'">Commentaire :</label>
                    <textarea class="form-control form-control-alt" :required="false" v-model="intervention.travaux[index]['comment']"
                              :name="'travaux-comments[index]'" :id="'travaux-comments['+index+']'"></textarea>

                </div>
            </div>
        </div>

        <button @click="addJob()" class="btn my-4 btn-block btn-info"><i class="far fa-plus-circle"></i> Ajouter un travail</button>


        <button
                class="btn btn-block btn-primary"
                @click="nextFormPage('resultmission-1-precisions')"
        >
            <i class="far fa-universal-access"></i> Précisions techniques, astuces
        </button>

        <button
                class="btn btn-block btn-primary"
                @click="nextFormPage('resultmission-1-travauxsupp')"
        >
            <i class="fas fa-traffic-cone"></i> Travaux supplémentaires à prévoir
        </button>

        <button
                class="btn btn-block btn-primary"
                @click="nextFormPage('resultmission-1-final')"
        >
            <i class="fas fa-flag-checkered"></i> Etat final de l'intervention
        </button>

    </div>
</template>


<script>
  // @ is an alias to /src
  import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';
  import {mapGetters, mapActions} from 'vuex'


  export default {
    mixins: [ResultMissionMixin],
    beforeMount: async function () {
      console.log('befo:', this.intervention);
      if (!this.intervention.travaux) {
        this.$set(this.intervention, 'travaux', []);
      }
    },
    methods: {

      ...mapActions('pictures',{deletePicturesByName: 'deleteByName'}),

        prevFilter: function(){
          this.nextFormPage('resultmission-1-etatinitial');
        },
        nextFilter: function(){
          this.nextFormPage('resultmission-1-precisions');
        },

      addJob: function () {
        this.intervention.travaux.push({
          'comment': '',
          'uid': new Date().getTime()
        });
        this.saveResultToStore();
      },

      removeJob: async function (index) {
        await this.deletePicturesByName('travaux-photos[' + index + ']');
        this.intervention.travaux.splice(index, 1);
        this.saveResultToStore();
      }
    }
  }
</script>

