<template>
	<div>
		<router-view class="h-100" :eventid="eventid"></router-view>
	</div>
</template>

<script>
	
	// @ is an alias to /src
	import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';
	
	export default {
		name: 'event',
	  	mixins: [ResultMissionMixin],
		props: {
			'action': Object
		},
	  beforeMount: async function () {
		  console.log(this.$route);
		await this.$store.dispatch('pictures/load', this.eventid);
	  }
	}
</script>
