<template>
	<div class="home h-100">
		<installPrompt></installPrompt>
		<button v-if="updateExists"
				class="btn btn-hero-success btn-block mb-4"
				@click="refreshApp">
			Une nouvelle version est disponible ! Cliquez ici
		</button>
		<div class="row h-100" v-touch:swipe.left.stop.prevent="nextFilter" v-touch:swipe.right.stop.prevent="prevFilter">
			<div class="col">
				<nav class="nav nav-tabs nav-tabs-alt nav-justified">
					<a v-for="filter in missionFilters" class="text-sm-center nav-link nav-item"
					   :class="{'active': filter==periodFilter}" @click.prevent="setPeriodFilter(filter)"
					   href="#">{{$t(filter)}}</a>
				</nav>
				
				<div class="block-content block-content-full px-0 tab-content">
					<div class="tab-pane active">
						<list-events :period="periodFilter"></list-events>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import installPrompt from '@/components/installPrompt.vue';
	import ListEvents from '@/components/ListEvents.vue';
	import {mapActions, mapGetters} from 'vuex'
	
	export default {
		name: 'home',
		components: {
			ListEvents,
			installPrompt
		},
		data: function () {
			return {
				missionFilters: ['Last', 'Today', 'Next'],
				refreshing: false,
				registration: null,
				updateExists: false
			}
		},
	  	created() {
			// Listen for swUpdated event and display refresh snackbar as required.
			document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
			// Refresh all open app tabs when a new service worker is installed.
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('config', {'config': 'getConfig'}),
			...mapGetters('events', {'periodFilter': 'getPeriodFilter'}),
		},
	  	mounted:
			function () {

			  if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function (position) {
				});
			  }
			},
	  	methods: {
			...mapActions('events', ['setPeriodFilter']),
			showRefreshUI(e) {
				// Display a button inviting the user to refresh/reload the app due
				// to an app update being available.
				// The new service worker is installed, but not yet active.
				// Store the ServiceWorkerRegistration instance for later use.
				this.registration = e.detail;
				this.updateExists = true;
			},
			refreshApp() {
				// Handle a user tap on the update app button.
				this.updateExists = false;
				// Protect against missing registration.waiting.
				if (!this.registration || !this.registration.waiting) {
					return;
				}
				this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
			},
            nextFilter(){
			    console.log('swipe next');
			    let index = this.missionFilters.indexOf(this.periodFilter)
                if (index != (this.missionFilters.length-1)) {
			        this.setPeriodFilter(this.missionFilters[index+1]);
                }
            },
            prevFilter(){
			    console.log('swipe prev');
			    let index = this.missionFilters.indexOf(this.periodFilter)
			    if (index >0) {
			        this.setPeriodFilter(this.missionFilters[index-1]);
                }
            }
		},
	}
</script>
