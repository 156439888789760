<template>

	<div v-touch:swipe.left.stop.prevent="nextFilter" v-touch:swipe.right.stop.prevent="prevFilter">

	<H2><i class="far fa-universal-access"></i> Précisions techniques, astuces</H2>

		<div
				class="block block-rounded block-bordered js-appear-enabled animated fadeIn"
				data-toggle="appear"
		>
			<div class="block-header block-header-default">
				<a class="font-w600" href="#">Accessibilité</a>
			</div>
			<div class="block-content">
				<div class="form-group my-2" :class="{required:false}">
					<label for="precisions-accessibilite-comment">Commentaire sur l'accessibilité :</label>
					<textarea class="form-control form-control-alt" :required="false" v-model="intervention['precisions-accessibilite-comment']"
							  :class="{'is-invalid':errors['precisions-accessibilite-comment']}" name="precisions-accessibilite-comment" id="precisions-accessibilite-comment"></textarea>
					<div v-if="errors['precisions-accessibilite-comment']" class="invalid-feedback">{{errors['precisions-accessibilite-comment']}}</div>
				</div>

				<pictures :uid="eventid" name="precisions-accessibilite-photo" label="Photo" class="my-2">PICTURESS</pictures>

			</div>

		</div>

		<div
				class="block block-rounded block-bordered js-appear-enabled animated fadeIn"
				data-toggle="appear"
		>
			<div class="block-header block-header-default">
				<a class="font-w600" href="#">Technique</a>
			</div>
			<div class="block-content">
				<div class="form-group my-2" :class="{required:false}">
					<label for="precisions-technique-comment">Commentaire sur la technique :</label>
					<textarea class="form-control form-control-alt" :required="false" v-model="intervention['precisions-technique-comment']"
							  :class="{'is-invalid':errors['precisions-technique-comment']}" name="precisions-technique-comment" id="precisions-technique-comment"></textarea>
					<div v-if="errors['precisions-technique-comment']" class="invalid-feedback">{{errors['precisions-technique-comment']}}</div>
				</div>

				<pictures :uid="eventid" name="precisions-technique-photo" label="Photo" class="my-2">PICTURESS</pictures>

			</div>

		</div>
		<div
				class="block block-rounded block-bordered js-appear-enabled animated fadeIn"
				data-toggle="appear"
		>
			<div class="block-header block-header-default">
				<a class="font-w600" href="#">Sécurité</a>
			</div>
			<div class="block-content">
				<div class="form-group my-2" :class="{required:false}">
					<label for="precisions-securite-comment">Commentaire sur la securite :</label>
					<textarea class="form-control form-control-alt" :required="false" v-model="intervention['precisions-securite-comment']"
							  :class="{'is-invalid':errors['precisions-securite-comment']}" name="precisions-securite-comment" id="precisions-securite-comment"></textarea>
					<div v-if="errors['precisions-securite-comment']" class="invalid-feedback">{{errors['precisions-securite-comment']}}</div>
				</div>

				<pictures :uid="eventid" name="precisions-securite-photo" label="Photo" class="my-2">PICTURESS</pictures>

			</div>

		</div>

		<button
				class="btn btn-block btn-primary"
				@click="nextFormPage('resultmission-1-bontravail')"
		>
			<i class="fas fa-user-hard-hat"></i> Bon de travail
		</button>


	</div>
</template>

<script>

  // @ is an alias to /src
  import ResultMissionMixin from '@/mixins/ResultMissionMixin.js';

  export default {
    mixins: [ResultMissionMixin],
	methods: {
        prevFilter: function(){
          this.nextFormPage('resultmission-1-bontravail');
        },
        nextFilter: function(){
          this.nextFormPage('resultmission-1-travauxsupp');
      },
	}
  }
</script>
