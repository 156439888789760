import ResultMissionBonTravail from '@/forms/1/ResultMissionBonTravail.vue'
import ResultMissionEtatInitial from '@/forms/1/ResultMissionEtatInitial.vue'
import ResultMissionFinal from '@/forms/1/ResultMissionFinal.vue'
import ResultMissionInformations from '@/forms/1/ResultMissionInformations.vue'
import ResultMissionPrecisions from '@/forms/1/ResultMissionPrecisions.vue'
import ResultMissionTravauxSupp from '@/forms/1/ResultMissionTravauxSupp.vue'

import store from '@/store/index';

const routebases = [
  {
    path: 'informations',
    nameMenu: '<i class="far fa-info-circle"></i> Informations',
    component: ResultMissionInformations
  },
  {
    path: 'etatinitial',
    nameMenu: '<i class="far fa-play"></i> Etat des lieux initial',
    component: ResultMissionEtatInitial,
    isOnError: function (event) {

      const photos = store.getters['pictures/getByName']('etatinitial-photo');
      //let intervention = store.getters['interventions/getIntervention'](event);
      return !photos || photos.length < 1;
    }
  },
  {
    path: 'bontravail',
    nameMenu: '<i class="fas fa-user-hard-hat"></i> Bon de travail',
    component: ResultMissionBonTravail,
    isDisabled: function(event) {
      // let intervention = store.getters['interventions/getIntervention'](event);
      return false;
    },

    isOnError: function (event) {

      let intervention = store.getters['interventions/getIntervention'](event);
      if (!intervention.travaux || intervention.travaux.length < 1)
        return true;


      const uid = intervention.travaux[0].uid;
      let photos = store.getters['pictures/getByName']('travaux-photos[' + uid + ']');
      if (!photos  || photos.length < 1)
        return true;

      return false;
    }
  },


  {
    path: 'precisions',
    nameMenu: '<i class="far fa-universal-access"></i> Précisions',
    component: ResultMissionPrecisions
  },

  {
    path: 'travauxsupp',
    nameMenu: '<i class="fas fa-traffic-cone"></i> Travaux supplémentaires',
    component: ResultMissionTravauxSupp
  },

  {
    path: 'final',
    nameMenu: '<i class="fas fa-flag-checkered"></i> Etat Final',
    component: ResultMissionFinal,
    isDisabled: function(event) {
      let intervention = store.getters['interventions/getIntervention'](event);
      return false;
    },
    isOnError: function (event) {

      let intervention = store.getters['interventions/getIntervention'](event);
      if (!intervention['final-etatfinal'])
        return true;

      if (!intervention['final-paiement']){
        return true;
      }

      if (intervention['final-paiement'] !== 'no' && intervention['final-montant'] === ''){
          return true;
      }

      if (intervention['final-paiement'] === 'check'){

        let photos = store.getters['pictures/getByName']('final-photo-check');
        if (!photos || photos.length < 1)
          return true;
      }
      return false;
    }

  },

];

let routes = [];

routebases.forEach(function (route) {
  routes.push({
    path: route.path,
    component: route.component,
    name: 'resultmission-1-' + route.path,
    props: true,
    meta: {
      selectEventId: true,
      logLayout: false,
      nameMenu: route.nameMenu
    },
    isDisabled: route.isDisabled ? route.isDisabled : () => false,
    isOnError: route.isOnError ? route.isOnError : () => false
  });
});

export default routes